/*
 * File: ModalSearchNOffer.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 6th October 2023 12:03:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 10th October 2023 02:12:45
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { MouseEventHandler, useMemo, useState, Fragment, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import MyModal from "Content/Modals/MyModal";
import translator from "MultiLanguage/TranslationWrapper";
import Styles from "Content/Modals/ModalSearchNOffer/ModalSearchNOffer.module.css";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";
import CarService from "Services/Car/CarService";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import CarSummary from "Content/Car/CarSummary";
import { useNavigate } from "react-router-dom";
import AddOfferOnCar from "Content/AddOfferOnCar/AddOfferOnCar";
import { SellInfo } from "Content/Sell/SellInfo/SellInfo";

type ModalProps = {
    show: boolean,
    onClose: MouseEventHandler<HTMLDivElement>,
    closeModal: MouseEventHandler,
    title: string,
};

function ModalSearchNOffer(props: ModalProps) {
    const navigate = useNavigate();
    const [options, setOptions] = useState<{id: string, label: string; disabled: boolean}[]>([]);
    const [value, setValue] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [car, setCar] = useState<any>({});
    const [spinner, setSpinner] = useState(false);
    const [isOkay, setIsOkay] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const selectedValue = useMemo(() => {
        let optionFound = options.filter((v) => v.label === value);
        if (optionFound.length > 0)
            return optionFound[0].label;
        return value;
    }, [options]);

    const selectableOptions = useMemo(() => {
        return options.map(v => v.label);
    }, [options]);

    useEffect(() => {
        const beforeSearch = setTimeout(async () => {
            handleSearchOptions(inputValue);
        }, 500);
        return () => clearTimeout(beforeSearch);
    }, [inputValue]);

    const handleSearchOptions = async (input: string) => {
        if (input.length >= 3) {
            let searchOptions = await CarService.getCarsByRegNb(input);
            setOptions(searchOptions.map(v => {
                    if (v.InSell.length > 0)
                        return {label: v.RegNb.toUpperCase(), id: v.id, disabled: v.Status === "SELLING" && v.InSell[0].Offers.some((val: any) => val.Gifted === true)};
                    return {label: v.RegNb.toUpperCase(), id: v.id, disabled: true};
                }
            ));
        }
    };

    const onOffer = async (func: Function) => {
        setSpinner(true);
        func().then((res: any) => {
            if (res.length === 0)
                setIsOkay(false);
            else
                setIsOkay(true);
            setSpinner(false);
            setOpenModal(true);
        }).catch(() => {
            setSpinner(false);
            setIsOkay(false);
            setOpenModal(true);
        });
    };

    return(
        <Fragment>
            <MyModal closeModal={props.closeModal} show={props.show} addCloseCross={true} onClose={props.onClose} footer={(() => { return (<></>); })()} title={""} className={`${Styles.myModal}`}>
                {
                    spinner ?
                        <div className={`${Styles.spinnerHolder}`}>
                            <LoadingScreen />
                        </div>
                        :null
                }
                <div className={`${Styles.formHolder}`}>
                    <Autocomplete
                        freeSolo
                        value={selectedValue}
                        onChange={async (event: any, newValue: string | null) => {
                            let option = options.find((v) => v.label === newValue)
                            if (option) {
                                let car = await CarService.getCarByID(option.id);
                                setCar(car);
                            }
                            setValue(newValue);
                        }}
                        filterOptions={x=>x}
                        inputValue={inputValue}
                        onInputChange={async (event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        getOptionDisabled={(option) => {
                            let opt = options.find((val) => option === val.label);
                            if (!opt)
                                return false;
                            return opt.disabled;
                        }}
                        id="combo-box-immat"
                        options={selectableOptions}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} autoFocus label={`${translator.decode("dynamic.regNb")}`} />}
                    />
                    {
                        value !== null && inputValue === value && car && car.CirculationDate !== undefined ?
                            <Fragment>
                                <hr />
                                <div className={`${Styles.carForm}`}>
                                    <CarSummary regNb={car.RegNb} VIN={car.VIN} mileage={car.Mileage} energy={car.Energy} circulationDate={car.CirculationDate}
                                        park={car.StoragePlace.Name ? car.StoragePlace.Name : car.StoragePlace.City} priceBuyNow={car.SellPrice} priceAutoBuy={car.MinAutobuyPrice} VAT={car.VAT} clientURL={RouterPathResolver.pathCar_Client.path.replace(":id", `${car.id}`)} otherURLs={{Report1: car.Report1, Report2: car.Report2, Maintenance: car.Maintenance}} />
                                    <Button type="button" className={`${Styles.carButton}`} onClick={() => navigate(RouterPathResolver.pathCar_BY_ID.path.replace(":id", car.id))}>{translator.decode("dynamic.seeCar").toUpperCase()}</Button>
                                </div>
                                <hr />
                                <SellInfo {...car.InSell.at(0)?.Sell} />
                                <p className="m-0">{translator.decode("messages.labels.Car")} {translator.decode(`car.status.${car.Status}`)}</p>
                                <AddOfferOnCar carID={car.id} onOffer={onOffer} />
                            </Fragment>
                        : null
                    }
                </div>
            </MyModal>
            <ModalConfirmation show={openModal} onClose={() => setOpenModal(false)} closeModal={() => setOpenModal(false)} isOkay={isOkay} />
        </Fragment>
    )
}

export default ModalSearchNOffer;
