/*
 * File: SingleSellHeader.tsx
 * Project: autobuy-back-office
 * File Created: Monday, 21st August 2023 06:06:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 21st May 2024 11:14:02
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Col, Form } from "react-bootstrap";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SiMicrosoftexcel } from "react-icons/si";
import Styles from "Content/Sell/SingleSell.module.css";
import EditablePageName from "Content/HeadingPage/EditablePageName/EditablePageName";
import translator from "MultiLanguage/TranslationWrapper";
import { PartnerType } from "Type/partner.type";
import { GroupType } from "Type/group.type";
import GroupList from "Content/GroupList/GroupList";
import { getSmallestOrderPicture, getSmallestOrderPictureID } from "Utils/Picture.utils";
import { ShowingPicture } from "Type/picture.type";
import { setDate } from "Utils/Sell.utils";
import ModalPack from "Content/Modals/ModalPack/ModalPack";
import DragGalery from "Content/DragGalery/DragGalery";
import VisualService from "Services/Visuals/VisualService";
import SellService from "Services/Sell/SellService";
import { PictureType, SellUpdate, UpdateSellObject, UpdatedSellIntel } from "Type/sell";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";
import TimerNbVo from "Content/TimerNbVo/TimerNbVo";
import CollapseBox from "Content/CollapseBox/CollapseBox";
import PartnerSelectionComponent from "Content/PartnerSelection/PartnerSelection";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SellTypeType } from "Content/Modals/ModalSell/ModalSell";
import { ModalConfirmRequest } from "Content/Modals/ModalConfirmRequest/ModalConfirmRequest";
import ModalCarPicMissing from "Content/Modals/ModalCarPicMissing/ModalCarPicMissing";
import { CarInSell } from "Type/CarElement.type.hold";
import ModalSellImgChange from "Content/Modals/ModalSellImgChange/ModalSellImgChange";

export type SingleSellHeaderProps = {
    nbCar: number;
    Name: string;
    sellID: string;
    EndDate: Date | string;
    StartDate: Date | string;
    Picture: PictureType;
    Status: string,
    PartnerPicture: PictureType;
    AdvertisedPicture?: PictureType;
    Advertise: boolean,
    Exclusive: boolean,
    HideLinks: boolean,
    HideCost: boolean,
    Partners: PartnerType[],
    SellGroups: {Group: GroupType}[],
    ExcludedSellGroups: {Group: GroupType}[],
    Editable?: boolean,
    SellType: string,
    nbView: number,
    nbOffer: number,
    CarList: CarInSell[],
}

function SingleSellHeader(props: SingleSellHeaderProps) {
    const [sellName, setSellName] = useState(props.Name);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(props.StartDate));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs(props.EndDate));
    const [showModalPack, setShowModalPack] = useState(false);
    const [advertise, setAdvertise] = useState(props.Advertise);
    const [advertisedBoxes, setAdvertisedBoxes] = useState<ShowingPicture[]>([]);
    const [exclusive, setExclusive] = useState(props.Exclusive);
    const [hideLinks, setHideLinks] = useState(props.HideLinks);
    const [hideCost, setHideCost] = useState(props.HideCost);
    const [isSendable, setIsSendable] = useState(false);
    const [sellStatus, setSellStatus] = useState(props.Status);
    const [sellPicture, setSellPicture] = useState(props.Picture.URI ?? "/Default.jpg");
    const sellPicID = useRef<string>(props.Picture.id);
    const myPartners = useRef<PartnerType[]>(props.Partners);
    const mySellGroups = useRef<GroupType[]>(props.SellGroups.map(val => val.Group));
    const myExcludedSellGroups = useRef<GroupType[]>(props.ExcludedSellGroups.map(val => val.Group));
    const myPartnerPicture = useRef<string>("");
    const myAdvertisedPicture = useRef<string>("");
    const updateObject = useRef<UpdateSellObject>({update: {}, add: {groups: [], excludedGroups: [], partners: []}, remove: {groups: [], excludedGroups: [], partners: []}});
    const [isOkay, setIsOkay] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalRequest, setOpenModalRequest] = useState<boolean>(false);
    const [openModalCarPic, setOpenModalCarPic] = useState<boolean>(false);
    const [openModalSellImgChange, setOpenModalSellImgChange] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [editable, setEditable] = useState<boolean>(props.Editable ?? false);
    const [buttonEnable, setButtonEnable]  = useState<boolean>(true);
    const [sellType, setSellType] = useState<SellTypeType[]>([]);
    const [title, setTitle] = useState("");
    const [requestFunc, setRequestFunc] = useState<Function>(() => () => {});
    const [sellTypeSelected, setSellTypeSelected] = useState("");

    const getSellTypeValue = useMemo(() => {
        if (sellTypeSelected === "buynowvalidation")
            return "buynow";
        else if (sellTypeSelected === "closedauctionai")
            return "closedauction";
        else
            return sellTypeSelected;
    }, [sellTypeSelected]);

    function updatePartners(data: PartnerType[]) {
        myPartners.current = data;
        let addedPartners = myPartners.current.filter((val) => !(props.Partners.map(v => v.id).includes(val.id)));
        let deletePartners = props.Partners.filter((val) => !(myPartners.current.map(v => v.id).includes(val.id)));
        updateObject.current.add.partners = addedPartners.map((val) => val.id);
        updateObject.current.remove.partners = deletePartners.map((val) => val.id);
        checkIsSendable();
    };

    function updateSellGroup(data: GroupType[]) {
        mySellGroups.current = data;
        let addedGroups = mySellGroups.current.filter((val) => !(props.SellGroups.map((group) => group.Group).find(elem => elem.Name == val.Name) !== undefined));
        let deleteGroups = props.SellGroups.map((group) => group.Group).filter((val) => !(mySellGroups.current.find(elem => elem.Name == val.Name) !== undefined));
        updateObject.current.add.groups = addedGroups.map((val) => val.id);
        updateObject.current.remove.groups = deleteGroups.map((val) => val.id);
        checkIsSendable();
    };

    function updateExcludedSellGroup(data: GroupType[]) {
        myExcludedSellGroups.current = data;
        let addedGroups = myExcludedSellGroups.current.filter((val) => !(props.ExcludedSellGroups.map((group) => group.Group).find(elem => elem.Name == val.Name) !== undefined));
        let deleteGroups = props.ExcludedSellGroups.map((group) => group.Group).filter((val) => !(myExcludedSellGroups.current.find(elem => elem.Name == val.Name) !== undefined));
        updateObject.current.add.excludedGroups = addedGroups.map((val) => val.id);
        updateObject.current.remove.excludedGroups = deleteGroups.map((val) => val.id);
        checkIsSendable();
    };

    function updatePartnerPicture(data: ShowingPicture[]) {
        myPartnerPicture.current = getSmallestOrderPictureID(data);
        const pict = getSmallestOrderPicture(data);
        if (pict && myPartnerPicture.current != props.PartnerPicture?.id)
            updateObject.current.update.PartnerPictureID = pict.id;
        else
            updateObject.current.update.PartnerPictureID = undefined;
        checkIsSendable();
    };

    function updateAdvertisedPicture(data: ShowingPicture[]) {
        setAdvertisedBoxes(data);
        myAdvertisedPicture.current = getSmallestOrderPictureID(data);
        const pict = getSmallestOrderPicture(data);
        if (pict && myAdvertisedPicture.current != props.AdvertisedPicture?.id)
            updateObject.current.update.AdvertisedPictureID = pict.id;
        else
            updateObject.current.update.AdvertisedPictureID = undefined;
        checkIsSendable();
    };

    function updateExclusive(isExclusive: boolean) {
        setExclusive(isExclusive);
        if (isExclusive != props.Exclusive)
            updateObject.current.update.Exclusive = isExclusive;
        else
            updateObject.current.update.Exclusive = undefined;
        checkIsSendable();
    };
    function updateHideLinks(isHideLinks: boolean) {
        setHideLinks(isHideLinks);
        if (isHideLinks != props.HideLinks)
            updateObject.current.update.HideLinks = isHideLinks;
        else
            updateObject.current.update.HideLinks = undefined;
        checkIsSendable();
    };
    function updateHideCost(isHideCost: boolean) {
        setHideCost(isHideCost);
        if (isHideCost != props.HideCost)
            updateObject.current.update.HideCost = isHideCost;
        else
            updateObject.current.update.HideCost = undefined;
        checkIsSendable();
    };

    function updateAdvertise(isAdvertise: boolean) {
        setAdvertise(isAdvertise);
        if (isAdvertise != props.Advertise)
            updateObject.current.update.Advertise = isAdvertise;
        else
            updateObject.current.update.Advertise = undefined;
        checkIsSendable();
    };

    function updateStartDate(date: dayjs.Dayjs | null) {
        if (!date)
            return null;
        let newDate = setDate(date, setStartDate);
        if (newDate != null) {
            updateObject.current.update.StartDate = newDate.toDate();
        } else {
            updateObject.current.update.StartDate = undefined;
        }
        checkIsSendable();
    };

    function updateEndDate(date: dayjs.Dayjs | null) {
        if (!date)
            return null;
        let newDate = setDate(date, setEndDate);
        if (newDate != null)
            updateObject.current.update.EndDate = newDate.toDate();
        else
            updateObject.current.update.EndDate = undefined;
        checkIsSendable();
    };

    function getSellTypeId(sellTypeName: string) {
        let ret = sellType.find(val => val.Name == sellTypeName);
        return ret?.id;
    };

    function selectSellType(selltype: string) {
        let sellTypeId = getSellTypeId(selltype);
        if (sellTypeId)
            updateObject.current.update.SellTypeID = sellTypeId;
        else
            updateObject.current.update.SellTypeID = undefined;
        checkIsSendable();
        setSellTypeSelected(selltype);
    };

    const checkUpdate = (update: SellUpdate) => {
        let updateKeys = Object.keys(update);
        for (let key of updateKeys) {
            if ((update as any)[key] != undefined)
                return true;
        }
        return false;
    };

    const checkAddOrRemove = (updatedIntel: UpdatedSellIntel) => {
        return (updatedIntel.groups.length > 0 || updatedIntel.excludedGroups.length > 0 || updatedIntel.partners.length > 0);
    };

    const checkIsSendable = () => {
        let checkCurrentUpdate = checkUpdate(updateObject.current.update);
        let checkCurrentAdd = checkAddOrRemove(updateObject.current.add);
        let checkCurrentRemove = checkAddOrRemove(updateObject.current.remove);

        if (checkCurrentUpdate || checkCurrentAdd || checkCurrentRemove)
            setIsSendable(true);
        else
            setIsSendable(false);
    };

    const handleChangeSellName = () => {
        if (props.Name != sellName)
            updateObject.current.update.Name = sellName;
        else
            updateObject.current.update.Name = undefined;
        checkIsSendable();
    };

    const handleCreatePack = (e: any) => {
        e.stopPropagation();
        setShowModalPack(true);
    };

    const handleTogglePackModal = (e: React.MouseEvent<HTMLElement>) => {
        setShowModalPack(false);
        window.location.reload();
    };

    const handleDownloadSell = (e: React.MouseEvent<HTMLElement>) => {
        SellService.downloadSell(props.sellID, () => {
            setIsOkay(false);
            setOpenModal(true);
        });
    };

    const handleDownloadGambasSell = (e: React.MouseEvent<HTMLElement>) => {
        SellService.downloadSellGambas(props.sellID, () => {
            setIsOkay(false);
            setOpenModal(true);
        });
    };

    const ManageSetters = (IsOkay: boolean, OpenModal: boolean, ButtonEnable: boolean) => {
        setIsOkay(IsOkay);
        setOpenModal(OpenModal);
        setButtonEnable(ButtonEnable);
    };

    const archiveSell = () => {
        SellService.closeSell(props.sellID).then(() => {
            ManageSetters(true, true, true);
            setSellStatus("ARCHIVED");
            handleModalRequestClose();
        }).catch((reason) => {
            let retError = reason.data?.error;
            console.error(`Unable to close sell: ${JSON.stringify(reason.data)}`);
            if (Array.isArray(retError) == true)
                retError = retError.join("\n");
            setError(retError);
            ManageSetters(false, true, true);
            handleModalRequestClose();
        });
    };

    const openSell = () => {
        SellService.openSell(props.sellID, endDate).then(() => {
            ManageSetters(true, true, true);
            setSellStatus("SELLING");
            handleModalRequestClose();
        }).catch((reason) => {
            console.error(`Unable to open sell: ${JSON.stringify(reason.data ?? {})}`);
            let retError = reason.response?.data?.error;
            if (Array.isArray(retError) == true)
                retError = retError.join("\n");
            setError(retError);
            ManageSetters(false, true, true);
            handleModalRequestClose();
        });
    };

    const closeSell = () => {
        SellService.stopSell(props.sellID).then(() => {
            ManageSetters(true, true, true);
            setSellStatus("SOLD");
            handleModalRequestClose();
        }).catch((reason) => {
            console.error(`Unable to end sell: ${JSON.stringify(reason.data)}`);
            let retError = reason.data?.error;
            if (Array.isArray(retError) == true)
                retError = retError.join("\n");
            setError(retError);
            ManageSetters(false, true, true);
            handleModalRequestClose();
        });
    };

    const handleOpenModal = (title: string, func: Function) => {
        setTitle(title);
        setRequestFunc(() => func);
        setOpenModalRequest(true);
    };

    const handleSellEmail = (e: React.MouseEvent<HTMLElement>) => {
        setButtonEnable(false);
        SellService.sendSellEmail(props.sellID).then(() => {
            ManageSetters(true, true, true);
        }).catch((reason) => {
            ManageSetters(false, true, true);
        });
    };

    const handlePullImages = (e: React.MouseEvent<HTMLElement>) => {
        setButtonEnable(false);
        SellService.getCarPictures(props.sellID).then(() => {
            ManageSetters(true, true, true);
        }).catch((reason) => {
            ManageSetters(false, true, true);
        });
    };

    const sendUpdate = () => {
        setButtonEnable(false);
        SellService.updateSell(updateObject.current, props.sellID).then(() => {
            ManageSetters(true, true, true);
        }).catch((reason) => {
            ManageSetters(false, true, true);
        });
    };

    useEffect(()=>{
        SellService.getSellTypes().then(res => {
            setSellType(res);
        });
    },[]);

    useEffect(() => {
        mySellGroups.current = props.SellGroups.map(item => item.Group);
    }, [props.SellGroups]);

    useEffect(() => {
        myExcludedSellGroups.current = props.ExcludedSellGroups.map(item => item.Group);
    }, [props.ExcludedSellGroups]);

    useEffect(() => {
        myPartners.current = props.Partners;
    }, [props.Partners]);

    useEffect(() => {
        if (advertise)
            VisualService.getVisuals("advertised").then( items => {
                if (items.data && items.data.Visuals) {
                    setAdvertisedBoxes(items.data.Visuals.map((item, index) => {
                        if (item.Picture)
                            return {id: item.Picture.id, Name: item.Picture.Name, Url: item.Picture.URI, order: index};
                        else if (item.Video)
                            return {id: item.Video.id, Name: item.Video.Name, Url: item.Video.URI, order: index};
                        return {id: "", Name: "", Url: "/assets/default", order: index};
                    }));
                }
           });
        else
           setAdvertisedBoxes([]);
    }, [advertise]);

    const handleModalClose = () => {
        setOpenModal(false);
        setButtonEnable(true);
    };

    const handleModalRequestClose = () => {
        setOpenModalRequest(false);
        setButtonEnable(true);
    };

    const handleModalCarPicClose = () => {
        setOpenModalCarPic(false);
        setButtonEnable(true);
    };

    const handleModalChangeSellPicture = () => {
        setOpenModalSellImgChange(false);
    };

    useEffect(() => {
        if (openModal === false)
            setIsOkay(false);
    }, [openModal]);

    function handleChangeSellPic(data: ShowingPicture) {
        if (data.Url)
            setSellPicture(data.Url);
        if (sellPicID.current !== data.id)
            updateObject.current.update.PictureID = data.id;
        else
            updateObject.current.update.PartnerPictureID = undefined;
        checkIsSendable();
    };

    return (
        <Fragment>
            {
                !buttonEnable ?
                <div className={`${Styles.spinnerHolder}`}>
                    <LoadingScreen />
                </div>
                : null
            }
            <EditablePageName editable={editable} name={sellName} changeName={setSellName} handleChangeAction={handleChangeSellName} />
            <h4> {translator.decode(`dynamic.carCount`)} {props.nbCar}</h4>
            <h5>{translator.decode(`sell.type.${props.SellType}`)}</h5>
            <div className={`${Styles.sellHeader}`}>
                <div className={`${Styles.buttonMiniGroup}`}>
                    <img className={`${Styles.carTitle}`} style={{objectFit:"cover", maxHeight:"6rem"}} src={sellPicture} alt="sell Logo" />
                    {editable ?
                    <button type="button" className="btn btn-outline-primary" onClick={() => setOpenModalSellImgChange(true)}>{translator.decode(`dynamic.modify`)}</button>
                    : null}
                </div>
                {editable ?
                    <Fragment>
                        <div className={`${Styles.box3}`}>
                            <div className={`${Styles.box1}`}>
                                <Form.Group className={`${Styles.formfield} ${Styles.datePicker}`} >
                                    <Col className={`${Styles.datePicker}`}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker label={translator.decode("dynamic.start")} ampm={false} disabled={startDate < dayjs()} format={"DD-MM-YYYY HH:mm"} value={startDate} onChange={(start: dayjs.Dayjs | null) => updateStartDate(start)}/>
                                        </LocalizationProvider>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`${Styles.formfield} ${Styles.datePicker}`} >
                                    <Col className={`${Styles.datePicker}`}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker label={translator.decode("dynamic.end")} ampm={false} format={"DD-MM-YYYY HH:mm"} value={endDate} onChange={(end: dayjs.Dayjs | null) => updateEndDate(end)}/>
                                        </LocalizationProvider>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`${Styles.formfield}`}>
                                    <Form.Label>
                                        {translator.decode("dynamic.exclusive")}
                                    </Form.Label>
                                    <Col>
                                        <Form.Check type="switch" checked={exclusive} onChange={() => updateExclusive(!exclusive)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`${Styles.formfield}`}>
                                    <Form.Label>
                                        {translator.decode("dynamic.hideLinks")}
                                    </Form.Label>
                                    <Col>
                                        <Form.Check type="switch" checked={hideLinks} onChange={() => updateHideLinks(!hideLinks)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`${Styles.formfield}`}>
                                    <Form.Label>
                                        {translator.decode("dynamic.hideCost")}
                                    </Form.Label>
                                    <Col>
                                        <Form.Check type="switch" checked={hideCost} onChange={() => updateHideCost(!hideCost)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`${Styles.formfield}`}>
                                    <Form.Label>
                                        {translator.decode("dynamic.status")}
                                    </Form.Label>
                                    <Col>
                                        <p>{translator.decode(`dynamic.${sellStatus}`)}</p>
                                    </Col>
                                </Form.Group>
                            </div>
                            <Form.Group className={`${Styles.flexSelect}`}>
                                <FormControl size="medium" className={`${Styles.selectSellType}`}>
                                    <InputLabel>{translator.decode("sell.list.headers.SellType")}</InputLabel>
                                    <Select className={`text-capitalize`} value={getSellTypeValue && getSellTypeValue !== "" ? getSellTypeValue : ""} label={translator.decode("sell.list.headers.SellType")} onChange={(e) => {selectSellType(e.target.value)}}>
                                        <MenuItem value="">
                                            <em>{translator.decode(`sell.type.choose`)}</em>
                                        </MenuItem>
                                        {
                                            sellType.filter((val: SellTypeType) => val.Name !== "buynowvalidation" && val.Name !== "closedauctionai").map((val: {id: string, Name: string, Activated: boolean}, index: number) => <MenuItem value={val.Name} key={`${val.Name}-${index}`}>{translator.decode(`sell.type.${val.Name}`)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    sellTypeSelected === "buynow" || sellTypeSelected === "buynowvalidation" ? <div> <input type="checkbox" checked={sellTypeSelected === "buynowvalidation"} onChange={(e) => {e.target.checked === true ? selectSellType("buynowvalidation") : selectSellType("buynow")}} /> {translator.decode("dynamic.validate")} </div> : null
                                }
                                {
                                    sellTypeSelected === "closedauctionai" || sellTypeSelected === "closedauction" ? <div> <input type="checkbox" checked={sellTypeSelected === "closedauctionai"} onChange={(e) => {e.target.checked === true ? selectSellType("closedauctionai") : selectSellType("closedauction")}} /> {translator.decode("dynamic.buynow")} </div> : null
                                }
                            </Form.Group>
                            <div className={`${Styles.box2}`}>
                                <Form.Group className={`${Styles.formfield}`}>
                                    <Form.Label>
                                        {translator.decode("dynamic.advertise")}
                                    </Form.Label>
                                    <Col>
                                        <Form.Check type="switch" checked={advertise} onChange={() => {updateAdvertise(!advertise)}}/>
                                    </Col>
                                </Form.Group>
                                {
                                    advertisedBoxes.length !== 0 ?
                                        <Form.Group className={`${Styles.formfield}`} >
                                            <Form.Label>
                                                {translator.decode("sell.visual.advertised")}:
                                            </Form.Label>
                                            <Col className={`${Styles.formfield}`}>
                                                <DragGalery items={advertisedBoxes} func={updateAdvertisedPicture} />
                                            </Col>
                                        </Form.Group>
                                        : null
                                }
                                <CollapseBox textBtn={translator.decode("dynamic.managePartners")}>
                                    <PartnerSelectionComponent partners={props.Partners} selectionCallback={updatePartners} pictureSelection={updatePartnerPicture}/>
                                </CollapseBox>
                                <CollapseBox textBtn={translator.decode("dynamic.manageGroups")}>
                                    <>
                                        <GroupList title={"dynamic.diffusion"} groups={mySellGroups.current} callback={updateSellGroup} />
                                        <GroupList title={"dynamic.exclude"} groups={myExcludedSellGroups.current} callback={updateExcludedSellGroup} />
                                    </>
                                </CollapseBox>
                            </div>
                            <div className={`${Styles.btnHolder}`}>
                                {sellStatus !== "ARCHIVED" ?
                                    <button type="button" disabled={!buttonEnable} className="btn btn-outline-danger" onClick={() => handleOpenModal(translator.decode(`dynamic.closeSell`), archiveSell)}> {translator.decode(`dynamic.closeSell`)} </button>
                                : null}
                                {sellStatus !== "SELLING" ?
                                    <button type="button" disabled={!buttonEnable} className="btn btn-outline-primary" onClick={() => handleOpenModal(translator.decode(`dynamic.openSell`), openSell)}> {translator.decode(`dynamic.openSell`)} </button>
                                : null}
                                {sellStatus === "SELLING" ?
                                    <button type="button" disabled={!buttonEnable} className="btn btn-outline-warning" onClick={() => handleOpenModal(translator.decode(`dynamic.endSell`), closeSell)}> {translator.decode(`dynamic.endSell`)} </button>
                                : null}
                                <button type="button" disabled={!buttonEnable && !isSendable} className="btn btn-outline-success" onClick={sendUpdate}> {translator.decode(`dynamic.save`)} </button>
                                <button type="button" disabled={!buttonEnable} className="btn btn-outline-secondary" onClick={handleSellEmail}>{translator.decode(`dynamic.resendEmail`)}</button>
                                <button type="button" disabled={!buttonEnable} className="btn btn-outline-primary" onClick={handleCreatePack}>{translator.decode(`dynamic.addCars`)}</button>
                                <button type="button" disabled={!buttonEnable} className="btn btn-outline-warning" onClick={handlePullImages}>{translator.decode(`dynamic.pullCarPictures`)}</button>
                                <button type="button" disabled={!buttonEnable} className="btn btn-outline-warning" onClick={() => setOpenModalCarPic(true)}>{translator.decode(`dynamic.pullCarPicMissing`)}</button>
                            </div>
                        </div>
                    </Fragment>
                :
                    <div className="d-flex gap-3">
                        <p>{translator.decode(`sell.type.${props.SellType}`)}</p>
                        <div className="d-flex">
                            {translator.decode("time.start")}{new Date(props.StartDate).toLocaleString()} <br/>
                            {translator.decode("time.end")}{new Date(props.EndDate).toLocaleString()}
                        </div>
                        <TimerNbVo nb_vehicules={props.nbCar} time={props.EndDate} moreStyle={Styles.flexEnd} />
                    </div>
                }
                <div className={`${Styles.offerView}`}>
                    <p className="m-0">{translator.decode("dynamic.offers")} {props.nbOffer}</p>
                    <p className="m-0">{translator.decode("dynamic.views")} {props.nbView}</p>
                </div>
                <div className={`${Styles.buttonMiniGroup}`}>
                    <button type="button" className="btn btn-outline-info" onClick={handleDownloadSell}>{translator.decode(`dynamic.export`)} <SiMicrosoftexcel /></button>
                    <button type="button" className="btn btn-outline-info" onClick={handleDownloadGambasSell}>{translator.decode(`dynamic.export`)} <img width={"16px"} height={"16px"} src={"/crayfish.png"}/><SiMicrosoftexcel /></button>
                </div>
                <ModalPack title={translator.decode("dynamic.pack")} sellID={props.sellID} show={showModalPack} onClose={handleTogglePackModal} closeModal={handleTogglePackModal} />
                <ModalConfirmation show={openModal} onClose={handleModalClose} closeModal={handleModalClose} isOkay={isOkay} additionnalMessage={error ?? undefined}/>
                <ModalCarPicMissing show={openModalCarPic} onClose={handleModalCarPicClose} closeModal={handleModalCarPicClose} title={translator.decode("dynamic.pullCarPicMissing")} carList={props.CarList} />
                <ModalConfirmRequest show={openModalRequest} onClose={handleModalRequestClose} closeModal={handleModalRequestClose} request={requestFunc} title={title} />
                <ModalSellImgChange show={openModalSellImgChange} onClose={handleModalChangeSellPicture} closeModal={handleModalChangeSellPicture} title={title} updateSellPic={handleChangeSellPic} />
            </div>
        </Fragment>
    )
}

export default SingleSellHeader;
